import React from 'react';
import { Trans } from 'react-i18next';

import { Text } from '@components';
import { Box } from '@core';
import { FadeInTitle, TitleWithIncrementalDiscount } from '@shared/deals-helpers';
import { PRICING_LINK } from '@shared/constants/constants';
import { GA4_EVENTS } from '@shared/constants/google';

import { PAGE_DATA as DEALS_PAGE_DATA, getCTAButtonText } from './deals';

export const PAGE_DATA = ({ variant, images, partnerName, partnerType, image }) => ({
  ...DEALS_PAGE_DATA({ variant, images }),
  headerCta: {
    text: ({
      discountPercentage,
      pricePerMonth,
      couponCode,
      subscriptionPeriod,
      isLoading,
      extraTime,
      t,
      currency,
    }) => ({
      title:
        partnerType === 'INF' ? (
          <TitleWithIncrementalDiscount
            finalDiscountPercentage={!isLoading && discountPercentage}
            i18nKey="campaigns:spring.trustedBy"
            components={[
              <Text.Heading1 as="span" color="danger" fontFamily="inherit" key={0} />,
              <FadeInTitle as="span" fontFamily="inherit" key={1}>
                <Box display="inline-flex" justifyContent="center" minWidth={{ _: 75, lg: 112 }} />
              </FadeInTitle>,
            ]}
            values={{ partnerName }}
          />
        ) : (
          <TitleWithIncrementalDiscount
            finalDiscountPercentage={!isLoading && discountPercentage}
            i18nKey="campaigns:spring.recommendedBy"
            components={[
              <Text.Heading1 as="span" color="danger" fontFamily="inherit" key={0} />,
              <FadeInTitle as="span" fontFamily="inherit" key={1}>
                <Box display="inline-flex" justifyContent="center" minWidth={{ _: 75, lg: 112 }} />
              </FadeInTitle>,
            ]}
            values={{ partnerName }}
          />
        ),
      subtitle: (
        <Trans
          i18nKey={'deals:header.description'}
          values={{
            planDetail: pricePerMonth
              ? t(
                  [
                    `deals:header.planDeal.${subscriptionPeriod}`,
                    '' /*setting default value to '' in case there is no translation for subscriptionPeriod*/,
                  ],
                  {
                    currency,
                    pricePerMonth,
                  }
                )
              : null,
            extraComment: pricePerMonth && extraTime && t(`plans:monthsExtra.${extraTime}`),
          }}
          components={[<Text.Subheading2 as="span" key={0} color="danger" display="inline-flex" />]}
        />
      ),
      button: {
        title: getCTAButtonText(t),
        link: PRICING_LINK,
        customEvent: { eventName: GA4_EVENTS.ctaClick },
      },
      description: [
        couponCode && (
          <Trans
            i18nKey="deals:header.appliedCoupon"
            values={{
              couponCode,
            }}
            components={[<Text.Body2Strong as="span" key={0} />]}
          />
        ),
        t('common:30dayMoneyBackGuarantee'),
      ],
    }),

    image: () => ({
      mobile: {
        src: image,
        alt: 'partner deals header',
        position: {
          height: 320,
        },
      },
      tablet: {
        src: image,
        alt: 'partner deals header',
        position: {
          height: 400,
        },
      },
      desktop: {
        src: image,
        alt: 'partner deals header',
        position: {
          top: { xl: -20 },
          height: { lg: 400, xl: 480 },
          left: '52%',
          right: { lg: 0, xl: 'unset' },
        },
      },
    }),
  },
});
